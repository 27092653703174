import styled from "styled-components";
import * as palette from "../../styles/variables";

export const PayContainer = styled.div`
* {
    font-family: ${palette.FONT_FAMILY} !important;
}
#pay * {
    box-sizing: border-box;
    width: 100%;
}
#pay {
    position: relative;
   
}
#pay_container_main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
#pay_container {
    width: 49.5vw;
    background: #ffffff;
    border: 0.03125vw solid #00b3db73;
    box-sizing: border-box;
    box-shadow: 0px 0px 0.75vw rgba(15, 36, 44, 0.15);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 2.1875vw 2.75vw 4vw 2.75vw;
}
#pay_block1 h1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 2.25vw;
    line-height: 2.8125vw;
    color: #ff4081;
}
#pay_block2 {
    width: 100%;
    height: 100%;
    margin-top: 1.5625vw;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10%;
}
#pay_block2_container1_wrapper {
    display: grid;
    grid-template-rows: 10.5625vw 10.5625vw;
    gap: 1.6875vw;
}
.pay_block2_container1 {
    cursor: pointer;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.03125vw solid #181b1d44;
    transition: 1s ease;
    background: #ffffff;
    border-radius: 0.5vw;
    position: relative;
}
.pay_block2_container2 {
    color: white;
    background: linear-gradient(135deg, #12c5e7 0%, #0385b1 100%);
    border: 0.375vw solid rgba(0, 179, 219, 0.3);
    box-shadow: 0px 0px 0.75vw rgba(15, 36, 44, 0.15);
    border-radius: 0.5vw;
    transition: 1s ease-in-out;
}
.pay_block2_container1 #success {
    display: none;
}
.pay_block2_container2 #success {
    position: absolute;
    background: #ffffff;
    border-radius: 0.25vw;
    top: 0;
    right: 0;
    width: 4.875vw;
    padding: 0.1875vw 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875vw;
    line-height: 1.125vw;
    align-items: center;
    text-align: center;
    color: #ff4081;
    display:block;
    margin: 0.5vw 0.5vw 0 0 ;
}
.pay_block2_container2 #success p{
    margin: 0;
}
#pay_block2_container1_block1 {
    width: fit-content;
    height: fit-content;
}
#plantype {
    font-size: 1.25vw;
    margin: 0;
}
#amount {
    font-size: 2.25vw;
    margin: 0;
}
#permonth {
    font-size: 1vw;
    margin: 0;
    line-height: 2vw;
}
/*===================paymentblock===================*/
#pay_block2_container2 {
    background: #ffffff;
    border: 0.03125vw solid #181b1d44;
    box-sizing: border-box;
    border-radius: 0.5vw;
    color: #0f1d46;
    opacity: 1;
    font-family: Source Sans Pro;
    font-style: normal;
    display: flex;
    flex-direction: column;
    padding: 2.25vw 1.875vw;
}
#pay_block2_container2 #pay_block2_container2_block1 {
    display: grid;
    padding: 0 0.75vw;
    height: 100%;
}
#pay_block2_mini_main {
    display: grid;
    grid-template-rows: auto auto;
}
#pay_block2_mini {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#pay_block2_container2_block1_title {
    width: 100%;
    border: none;
    border-bottom: 0.0125vw solid #00000048;
    height: 3vw;
}
#pay_block2_container2_block1_title h1 {
    margin: 0;
    font-size: 1.25vw;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    color: #0f1d46;
}
#pay_block2_mini_container p {
    color: #0f1d46;
    margin: 0;
}
#pay_block2_mini #pay_block2_mini_container {
    width: fit-content;
    height: fit-content;
}
#prize_title {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 1vw;
    line-height: 1.5vw;
    color: #0f1d46;
}
#pay_prize {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25vw;
    color: #0f1d46;
}
#pay_block2_cupon {
    width: 100%;
    height: 2.5vw;
    border: 0.03125vw solid #00b3db;
    box-sizing: border-box;
    border-radius: 0.25vw;
    position: relative;
}
#pay_block2_cupon input {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    color: #0f1d46;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875vw;
    line-height: 1.5vw;
    background: transparent;
    position: absolute;
    padding: 0.625vw 3.2vw  0.625vw  0.625vw ;
    outline: none;
}
#applybtn {
    color: #ff4081;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875vw;
    line-height: 1.5vw;
    cursor: pointer;
    position: absolute;
    width: fit-content;
    right: 0.625vw;
    top: 0.375vw;
}
#pay_block2_container2_block1_btn button {
    background: #ff4081;
    border-radius: 0.5vw;
    width: 100%;
    height: 3.5vw;
    border: none;
    outline: none;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125vw;
    color: white;
}
#login_main .footer {
    margin-top: 10vw;
}
@media(max-width:750px){
    #pay_block2 {
        display: flex;
        flex-direction: column;
       
    }
    #plantype {
        font-size: 20px;
    }
    #amount {
        font-size: 20px;
    }
    #permonth {
        font-size: 14px;
    }
    .pay1{
        line-height:2px;
    }

    #pay_container {
        width: 93.5vw;
        height: 120vw;
    }
    #pay_block2_container1_wrapper {
        display: grid;
        grid-template-rows: 26.5625vw 26.5625vw;
        gap: 3.6875vw;
    }
    .pay_block2_container2 #success{
        width: 19.875vw;
    }
    #pay_block1 h1 {
        font-size:4vw;
    }
    .h1{
        font-size:12px !important;
    }
    #pay_block2_container2_block1_title {
        width: 100%;
        border: none;
        border-bottom: 0.5vw solid #00000048;
        height:4vw;
    }
    
    #pay_prize {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 3vw;
        color: #0f1d46;
    }
    #prize_title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 3.5vw;
        line-height: 4.5vw;
        color: #0f1d46;
    }
    #pay_block2_cupon input {
        width: 100%;
        height: 100%;
        border: none;
        position: absolute;
        color: #0f1d46;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 2vw;
        line-height: 3vw;
        background: transparent;
        position: absolute;
        padding: 0.625vw 3.2vw 0.625vw 0.625vw;
        outline: none;
    }
    #applybtn {
        color: #ff4081;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 2vw;
        line-height: 5vw;
        cursor: pointer;
        position: absolute;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        right: 0.625vw;
        top: 0.375vw;
    }
    #pay_block2_container2_block1_btn button {
        background: #ff4081;
        border-radius: 0.5vw;
        width: 100%;
        height: 7.5vw;
        border: none;
        outline: none;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: 600;
        font-size: 2vw;
        color: white;
    }
     #pay_block2_cupon {
        width: 100%;
        height: 5.5vw;
        border: 0.03125vw solid #00b3db;
        box-sizing: border-box;
        border-radius: 0.25vw;
        position: relative;
        

}
`